<template>
  <div class="wbobyy">
    <div id="wrapper" class="wrapper">
        <div class="input-data">
            <input type="text" style="width: 300px;padding: 0;margin: 0;" v-model="Noitem" onkeyup="value=value.replace(/[^a-zA-Z0-9*]/g,'').toUpperCase()" @blur="Noitem=Noitem.replace(/[^a-zA-Z0-9*]/g,'').toUpperCase()" oninput="if (value.trim() === '') { value = null; }" @keyup.enter="AdminDataSelect(Noitem, Numbers)" required>
            <select class="selectxz" v-show="Noitem.length > 0" v-model="Type">
              <option value="0">{{$t('All.进料单号')}}</option>
              <option value="1">{{$t('All.非领非退')}}</option>
            </select>
            <input type="text" style="width: 200px;padding: 0;margin: 0;" v-model="Numbers" onkeyup="value=value.replace(/[^a-zA-Z0-9]/g,'').toUpperCase()" @blur="Numbers=Numbers.replace(/[^a-zA-Z0-9]/g,'').toUpperCase()" oninput="if (value.trim() === '') { value = null; }" @keyup.enter="AdminDataSelect(Noitem, Numbers)" required>
            <div class="underline">
              <i class="el-icon-circle-close" v-show="Noitem.length > 0" style="position: absolute;top: -29px;right: 290px;color:#9fa1a7;" @click="Noitem = ''"></i>
              <i class="el-icon-circle-close" v-show="Numbers.length > 0" style="position: absolute;top: -29px;right: 10px;color:#9fa1a7;" @click="Numbers = ''"></i>
              <el-button type="primary" style="position: absolute;top: -35px;right: -50px;" size="mini" icon="el-icon-search" @click="AdminDataSelect(Noitem, Numbers)"></el-button>
            </div>
            <label>{{$t('All.请输入IQC料号')}}</label>
            <label style="margin-left: 310px;">{{$t('All.订单号')}}</label>
        </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
      Noitem: '',
      Numbers: '',
      Type: 0
    }
  },
  created () {
  },
  props: ['Addtab', 'RemoveTab', 'Renewtargename', 'Rendw'],
  mounted () {
  },
  methods: {
    ...mapMutations(['UserData']),
    async AdminDataSelect (row, row1) {
      if (row === '') return this.$message.error(this.$t('All.请输入IQC料号'))
      if (row1 === '') return this.$message.error('请输入订单号')
      const postdata = {
        Item: row,
        Order: row1,
        UserData: this.$store.state.Login,
        Type: this.Type
      }
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/Newiqc/NewIQCSelectauser', postdata)
        if (res.status !== 200) {
          loadingInstance.close()
          this.$message.error(res.msg)
          return
        }
        loadingInstance.close()
        this.UserData(res.response)
        this.Addtab(this.$t('All.新IQC检验单') + '-' + res.response.Numbers, 'NewUseIQC')
        this.RemoveTab(this.$t('All.新IQC检验单'))
      } catch (error) {
        loadingInstance.close()
        this.$message.error(error)
      }
    }
  }
}
</script>
<style lang="less" scoped>
  .wbobyy{
  position:absolute/fixed;
  left:0;
  right:0;
  top:0;
  bottom:0;
  margin:auto;
  position: absolute;
  height: 0px;
  width: 580px;
  .wrapper{
    width: 580px!important;
  }
  .input-data{
    display: flex;
    justify-content: space-around;
    border-bottom: 2px solid silver;
  }
  .selectxz {
    border: none;
    text-align: center;
    width: 80px;
  }
  .selectxz:focus-visible{
    outline: none;
  }
}
</style>
